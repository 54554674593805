html,
body {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 100%;
  width: 100%;
}
.content {
  display: flex;
  flex-direction: row;
  padding: 20px;
}
.left {
  width: 25%;
  min-width: 360px;
  max-width: 380px;
  display: flex;
  flex-direction: column;
}
.right {
  width: calc(100% - 380px);
  margin-left: 50px;
}
.is-full-page {
  width: 100%;
  margin-left: 0;
}
.ossPathLayout {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.listLayout {
  display: block;
  height: 750px;
  overflow: auto;
}
.scoreLayout {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;
}
p {
  margin: 0;
}
.video-box-full {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: auto;
}
.video-box {
  display: flex;
  flex-direction: column;
  position: relative;
  width: auto;
  overflow: hidden;
}
.video-box-active {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
}
.video-box-active-other {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  flex-direction: row;
}
.video-top-box {
  display: flex;
  position: absolute;
  top: 0px;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 9;
  max-width: 100%;
  min-width: 0%;
}
.Y-axis-top {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  z-index: 100;
  cursor: pointer;
}
.Y-axis-top::before,
.Y-axis-top::after {
  width: 3px;
  left: 50%;
  margin-left: -1px;
  content: " ";
  display: block;
  background: white;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 30;
}
.Y-axis-top::before {
  bottom: 50%;
  margin-bottom: 19px;
}
.Y-axis-top::after {
  top: 54.5%;
  top: calc(50% + 30px);
}
video {
  object-fit: cover;
}
.video-bottom {
  width: 100%;
}
/* video::-webkit-media-controls-fullscreen-button {
        display: none;
    }
    video::-webkit-media-controls-play-button {
        display: none;
    }
    video::-webkit-media-controls-timeline {
        display: none;
    }
    video::-webkit-media-controls-current-time-display{
        display: none;            
    }
    video::-webkit-media-controls-time-remaining-display {
        display: none;            
    }
    video::-webkit-media-controls-mute-button {
        display: none;            
    }
    video::-webkit-media-controls-toggle-closed-captions-button {
        display: none;            
    }
    video::-webkit-media-controls-volume-slider {
        display: none;            
    }
    video::-webkit-media-controls-enclosure{ 
        display: none;
    } */
/*控制条样式*/
.video-control {
  width: 100%;
  height: 40px;
  padding: 5px;
  line-height: 50px;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.control-leftview {
  position: relative;
  z-index: 5;
  display: flex;
}
.control-btn {
  display: inline-block;
  width: 30px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.control-btn .video-play-img {
  width: 30px;
  height: 30px;
  display: block;
}
.progress-box {
  width: 100%;
  padding: 0 30px 0 30px;
}
.progress-box-body {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}
.progress-box-body .pro_pone {
  color: #fff;
}
.progress_bar_box {
  flex: 1 1;
  margin: 0px 16px 0 16px;
}
.video-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  background: transparent;
  overflow: hidden;
}
.video-shade p {
  text-align: center;
  min-width: 80px;
  height: 30px;
  line-height: 30px;
  color: red;
  font-size: 20px;
}
.display-none-style {
  position: fixed;
  top: -10000px;
}
.video-bottom-box {
  position: relative;
  z-index: 1;
}
.video-top-box-div {
  position: relative!important;
  display: flex;
  align-items: center;
}
.is-progress-style {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.is-progress-style p {
  color: #fff;
  font-size: 18px;
}
.video-react .video-react-big-play-button {
  display: none;
}
.video-react-control-bar {
  display: none;
}
.video-react-has-started .video-react-control-bar {
  opacity: 0;
}
.video-name-box {
  position: relative;
  height: 40px;
  background-color: #000;
}
.video-name-box p {
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  /* font-size: 30px; */
}
.div-round {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 52px;
  height: 52px;
  margin-top: -19px;
  margin-left: -26px;
  border: 3px solid #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.div-round .move-icon {
  width: 20px;
  height: 20px;
}
.move-right-icon {
  transform: rotate(180deg);
}
.is-none {
  display: none;
}
.hlayout {
  padding: 10px;
}
.magnifier-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #000;
  overflow: hidden;
}
.magnifier-content {
  margin-top: 60px;
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
}
.magnifier-left {
  position: relative;
  border: 2px solid #fff;
  overflow: hidden;
}
.pre_border {
  position: absolute;
  border: 1px solid red;
  z-index: 200;
}
.magnifier-box {
  width: 100%;
  height: 100%;
}
.magnifier-bg .magnifier-title {
  text-align: center;
  color: #1890ff;
  font-size: 20px;
  height: 40px;
}
.magnifier-icon {
  text-align: center;
  height: 20px;
  line-height: 20px;
}
.CloseOutlined {
  margin-top: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  background-color: #fff;
  color: red;
}
.magnifier-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  color: red;
  text-indent: 10px;
}
.sourse {
  z-index: 120;
  position: absolute;
  top: 0;
  left: 0;
  background-color: aquamarine;
  width: 100%;
  height: 100%;
  text-align: center;
  color: red;
  font-size: 20px;
}
.pinjie-sourse {
  position: relative;
  top: 0;
  left: 0;
  text-align: center;
  color: red;
  font-size: 20px;
}
.sourse-mask {
  z-index: 1000000;
  position: absolute;
  top: 0;
  left: 0;
}
.pinjie-sourse-mask {
  z-index: 1000000;
  position: absolute;
  top: 0;
  left: 0;
}
.plusCircleTwoTone {
  font-size: 20px;
}
.magnifier-top {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 40px !important;
  z-index: 9999;
}
.screen-full-icon {
  text-align: center;
  width: 50px;
  color: #fff;
  font-size: 25px;
}
.imgDown {
  background-color: #fff;
}
.radio_group {
  margin-left: 10px;
}
.description {
  margin: 20px 0px;
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}
.select-title {
  margin-right: 8px;
}
