.account {
  color: #fff;
}
.dirlayout {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 50px;
  top: 0;
}
.urlText {
  display: block;
  color: #fff;
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
