.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ececec;
}
.form {
  margin-left: -30px;
}
