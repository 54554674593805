.hlayout {
  display: flex;
  margin: 0;
  padding: 14px 26px;
}
.hindex {
  width: 40px;
  text-align: center;
}
.hname {
  min-width: 220px;
  flex: auto;
  word-break: break-all;
  text-overflow: clip;
  text-align: center;
}
.hscore {
  width: 50px;
  text-align: center;
}
