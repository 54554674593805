.OssItemLayout {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  padding: 5px 24px;
  border-bottom: 1px solid #dddddd;
}
.OssItemLayoutSelect {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 24px;
  border: 1px solid #5f9cff;
}
.index {
  width: 40px;
  text-align: center;
}
.name {
  min-width: 220px;
  flex: auto;
  word-break: break-all;
  text-overflow: clip;
  padding: 0px 6px;
  border-left: 1px solid #dddddd;
}
.score {
  width: 50px;
  text-align: center;
  border-left: 1px solid #dddddd;
}
